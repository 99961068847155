import React, { useState, useEffect, Suspense, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate,useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import { Shimmer } from './componant/screens/layout/Shimmer';
import 'react-loading-skeleton/dist/skeleton.css';
// import { Secure, FeedSecure, LoginSecure, InSecure } from './componant/utils/RouteRedirect';
import useLocalStorage from './componant/utils/RedirectRoute';

const Forgotpassword = React.lazy(() => import('./componant/screens/newRegister/ForgotPassword'));
const FallLoader = React.lazy(() => import('./componant/utils/Loader'));
const Login = React.lazy(() => import('./componant/screens/newRegister/EmailVerify'));
const FeedPage = React.lazy(() => import('./componant/screens/FeedPage/Index'));
const UserDetail = React.lazy(() => import('./componant/screens/FeedPage/UserDetail/Index'));
const Transaction = React.lazy(() => import('./componant/screens/Transaction/Calender'));
const Progress = React.lazy(() => import('./componant/screens/Transaction/Progress'));
const PersonalInfo = React.lazy(() => import('./componant/screens/newRegister/PersonalInfo'));
const BusiInfo = React.lazy(() => import('./componant/screens/newRegister/BusinessInfo'));
const BankInfo = React.lazy(() => import('./componant/screens/newRegister/BankDetails'));
const License = React.lazy(() => import('./componant/screens/newRegister/VerifybusinessInfo'));
const Otp = React.lazy(() => import('./componant/screens/newRegister/OtpVerify'));
const Chats = React.lazy(() => import('./componant/screens/chatPage/ChatscreenNew'));
const Subscribtion = React.lazy(() => import('./componant/screens/subscription/Subscription'));
const Pavilion = React.lazy(() => import('./componant/screens/Pavilion/Pavilion'));
const HomePage = React.lazy(() => import('./componant/screens/HomePage/Home1'));
const Agreement = React.lazy(() => import('./componant/screens/Agreement/Agreement'));
const Main = React.lazy(() => import('./componant/screens/Vault/Main'));
const Suggestion = React.lazy(() => import('./componant/screens/mainpage/Mainpage'))
const Notification = React.lazy(() => import('./componant/screens/Notification/index'))
const Profile = React.lazy(() => import('./componant/screens/profile/Profile'))
const UserChat = React.lazy(()=> import('./componant/screens/FeedPage/UserDetail/UserChat'))
const Pages = React.lazy(()=> import('./componant/screens/Pages/Pages'))
const AddPage = React.lazy(()=> import('./componant/screens/Addpage/Addpage'))
const invoice = React.lazy(()=> import('./componant/screens/Transaction/Invoice'))

function App() {
  var [Logindata, setLogindata] = useLocalStorage("LOGINDATA");
  const Secure = (Component) => {
    const token = Logindata;
    if (!token?.user?._id) {
      return <Navigate to="/login" />;
    }
    return <Component />;
  };

  const FeedSecure = (Component) => {
    const token = Logindata;
    if (!token) {
      return <Navigate to="/login" />;
    }
    return <Component />;
  };

  const LoginSecure = (Component) => {
    const token = Logindata;
    if (token?.user?._id) {
      return <Navigate to="/feed-page" />;
    }
    return <Component />;
  };

  const InSecure = (Component) => {
    const verifyData = localStorage.getItem("VERIFYDATA");
    if (verifyData) {
      return <Navigate to="/personal_info" />;
    }
    return <Component />;
  };
  
  const routes = [
    { path: '/login', element: LoginSecure(Login) },
    { path: '/', element: LoginSecure(Login) },
    { path: '/business-info', element: LoginSecure(BusiInfo) },
    { path: '/bank_info', element: LoginSecure(BankInfo) },
    { path: '/personal_info', element: LoginSecure(PersonalInfo) },
    { path: '/main', element:Secure(Main) },
    { path: '/license', element: LoginSecure(License) },
    { path: '/notify', element:Secure(Notification)},
    { path: '/user/:id', element:Secure(UserDetail)},
    { path: '/otp/:gmail', element:InSecure(Otp) },
    { path: '/pavilion', element: Secure(Pavilion) },
    { path: '/chats', element: Secure(Chats)},
    { path: '/subscribe', element:Secure(Subscribtion) },
    { path: '/terms/:id', element: Secure(Agreement) },
    { path: '/transactiton', element: Secure(Transaction)},
    { path: '/progress', element: Secure(Progress)},  
    { path: '/forgot_password', element: <Forgotpassword /> },
    { path: '/suggestions', element: Secure(Suggestion)},
    { path: '/profile', element: Secure(Profile )},
    { path: '/feed-page', element: FeedSecure(FeedPage)},
    { path: '/UserChat', element:Secure(UserChat)},
    { path: '/pages', element:Secure(Pages)},
    { path: '/add_page', element:Secure(AddPage)},
    { path: '/invoice', element:Secure(invoice) }
  ];

  return (
      // <Router basename="/client">
       <Router>
      <Suspense fallback={<FallLoader/>}>
        <ToastContainer />
        <Routes>
          {routes.map((route, index)=>(
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
